// components/Navbar.tsx
"use client"
import React, { useState, useEffect } from 'react';
import Image from "next/image";
import { useAppSelector, useAppDispatch, useAppStore } from '@/redux/hooks';
import {
   updateFloor,
   updateSelectedTheme,
  //  getSelectedTheme
   } from '@/redux/features/user/userSlice';
import Link from "next/link";
import LocaleSwitcher from './LocaleSwitcher';
import { useTranslations} from 'next-intl';
//  Import Images
import bitbenLogoLight from '../../assets/logo/bitben_logo_light.svg';
import bitbenLogoDark from '../../assets/logo/bitben_logo.svg';

export const NavbarFull: React.FC = () => {
    // Start Redux
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const selectedThemeName = useAppSelector(state => state.userSesssion.selectedThemeNameLS);
    const t = useTranslations('NavbarLinks');
    // const [selectedThemeName, setSelectedThemeName] = useState(store.getState().userSesssion.selectedThemeNameLS);
    // End Redux relevant stuff
    const [isOpen, setIsOpen] = useState(false);
    // const [isDarkTheme, setIsDarkTheme] = useState(store.getState().userSesssion.selectedThemeNameLS == "light" ? false : true);    // generates an error
    // const [isDarkTheme, setIsDarkTheme] = useState(useAppSelector(state => state.userSesssion.selectedThemeNameLS) == "light" ? false : true);    // generates an error
    // const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [isDarkTheme, setIsDarkTheme] = useState(selectedThemeName === "light" ? false : true);
    // const [isDarkTheme, setIsDarkTheme] = useState(store.getState().userSesssion.isDarkTheme);
    // const [isDarkTheme, setIsDarkTheme] = useState(useAppSelector(state => state.userSesssion.isDarkTheme));

    const [isRTL, setIsRTL] = useState(false);
  
    useEffect(() => {
      setIsDarkTheme(selectedThemeName === "light" ? false : true);
    }, [selectedThemeName]);

  //   useEffect(() => {
  //     const unsubscribe = store.subscribe(() => {
  //       // debugger;
  //         setSelectedThemeName(store.getState().userSesssion.selectedThemeNameLS);
  //         //setIsDarkTheme(store.getState().userSesssion.selectedThemeNameLS == "light" ? false : true);
  //     });
     
  //     // Clean-up function
  //     return () => unsubscribe();
  // }, [store]);

    
    
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
  
    const toggleTheme = () => {

        // as it is a toggle event we need to invert it!
        const wantedToSwitchToThemeName = !isDarkTheme ? "dark" : "light";
        dispatch(updateSelectedTheme(wantedToSwitchToThemeName));
    };
  
    const toggleDirection = () => {
      setIsRTL(!isRTL);
    };
  
    return (
        <nav className={`${isDarkTheme ? 'bg-[#432680]' : 'white'} shadow-lg relative w-full z-10 top-0`}>
         {/* <nav className={`bg-${isDarkTheme ? 'logoPrimary' : 'white'}-${isDarkTheme ? '500' : '100'} shadow-lg relative w-full z-10 top-0`}> */}
         {/* <nav className={`bg-${isDarkTheme ? 'gray' : 'white'}-${isDarkTheme ? '800' : '100'} shadow-lg relative w-full z-10 top-0`}> */}
          <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isRTL ? 'rtl' : 'ltr'}`}>
            <div className={`flex items-center justify-between h-16 ${isRTL ? 'md:flex-row-reverse' : ''}`}>
              <div className="flex-shrink-0">
                {/* Your logo or branding goes here */}
                <a href="/" className={`text-${isDarkTheme ? 'white' : 'black'} font-bold text-lg`}>
                    {/* {
                      isDarkTheme ?   <Image
                      src="assets/logo/bitben_logo_light.svg"
                      alt="bitben Logo"
                      height={0}
                      width={0}
                      style={{width:'120px', height: "auto" }}
                      />
                      :
                      <Image
                      src="assets/logo/bitben_logo.svg"
                      alt="bitben Logo"
                      height={0}
                      width={0}
                      style={{width:'120px', height: "auto" }}
                      />

                    } */}
                    <Image
                      priority
                      src={isDarkTheme ? bitbenLogoLight : bitbenLogoDark}
                      alt="bitben Logo"
                      height={0}
                      width={0}
                      style={{width:'150px', height: "auto" }}
                      />
                </a>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {/* Replace the links with your actual navigation links */}
                  <a href="/" className={`px-3 py-2 rounded-md text-sm font-medium text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_home")}</a>
                  <a href="/services" className={`px-3 py-2 rounded-md text-sm font-medium text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_services")}</a>
                  {/* <a href="/pricing" className={`px-3 py-2 rounded-md text-sm font-medium text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_pricing")}</a> */}
                  {/* <a href="/support" className={`px-3 py-2 rounded-md text-sm font-medium text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_support")}</a> */}
                  <a href="/about" className={`px-3 py-2 rounded-md text-sm font-medium text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_about")}</a>
                  
                  <LocaleSwitcher />
                </div>
              </div>
              <div className="md:hidden">
                <button aria-label="Toogle Menu Hamburger"
                  onClick={toggleMenu} 
                  className={`inline-flex items-center justify-center p-2 rounded-md text-${isDarkTheme ? 'gray' : 'gray'}-400 hover:text-white hover:${isDarkTheme ? 'bg-[#ffffff]' : 'bg-[#ffffff]'}-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
                >
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    {isOpen ? (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    ) : (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                    )}
                  </svg>
                </button>
              </div>
              {/* <div className="flex items-center">
                <button aria-label="Toogle Theme Name" onClick={toggleTheme} className="text-gray-400 hover:text-gray-800 focus:outline-none mr-4">
                  {isDarkTheme ? 'Light Theme' : 'Dark Theme'}
                </button>
                {/* <button onClick={toggleDirection} className="text-gray-400 hover:text-gray-800 focus:outline-none">
                  {isRTL ? 'LTR' : 'RTL'}
                </button> */}
              {/* </div> */}
            </div>
            
          </div>
          {isOpen && (
            <div className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {/* Replace the links with your actual navigation links */}
                {/* This is the Mobile Menu */}
                <a href="/" className={`px-3 py-2 block rounded-md font-medium text-base text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_home")}</a>
                <a href="/services" className={`px-3 py-2 block rounded-md font-medium text-base text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_services")}</a>
                <a href="/about" className={`px-3 py-2 block rounded-md font-medium text-base text-${isDarkTheme ? 'white' : 'bitben-violet'} hover:${isDarkTheme ? 'bg-[#ffffff]' : 'gray'} hover:text-bitben-green`}>{t("link_about")}</a>
                
                <LocaleSwitcher />
              </div>
            </div>
          )}
          
        </nav>
      );
  };
